<template>
  <el-card>
    <div slot="header">
      <h3>1排名提前</h3>
    </div>

  <Pay ref="pay" :pay_data="pay_data" />
    <div class="body">
      <el-form :model="form" label-width="120px">
        <el-form-item label="开通日期">
          <el-row>
            <el-col :span="13">
              <el-date-picker
                  v-model="form.rangeDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-col>
            <el-col :span="4">
              <el-input v-model="form.user" placeholder="天数"></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="价格">
          <h3>￥ 199</h3>
        </el-form-item>

        <el-form-item>
          <el-button @click="handleSend3" >支付</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>

<script>
    // import Pay from './Pay.vue'
  import Pay from '@/components/Pay'
  export default {
    name: "RankIndex",
    components: { Pay },
    data() {
      return {
        form: {},
		pay_data:{
			uid:0, 
			id:0,
		},
      }
    },
	methods: {  
	   handleSend3() {  
		   
		   	this.pay_data.uid = sessionStorage.getItem('uid');
		    this.pay_data.id = 10;
		    this.$refs.pay.open();
	   },
	},
  }
</script>

<style scoped lang="scss">
  .el-card {
    min-height: 1050px;

    .el-card__header {
      h3 {
        margin: 0;
        padding: 0;
      }
    }

    .body {
      .el-form {
        width: 80%;
        margin: auto;

        h3 {
          margin: 0;
          padding: 0;
        }

        .el-button{
          width: 120px;
          margin-left: 30%;
          transform: translateX(-50%);
          margin-top: 40px;
        }
      }
    }
  }
</style>
